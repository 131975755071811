<template>
  <div class="container py-8">
    <div class="py-2 col-12 col-md-8 col-lg-6 mx-auto">
      <div :class="'alert ' + alertType" role="alert" v-if="content">
        {{ content != "Forbidden" ? content : errorMessage }}
      </div>
      <button class="col-12 btn btn-primary text-white" @click="goToHome">
        Go home
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  data() {
    return {
      status: null,
      content: null,
      errorMessage: "There's something wrong with the verification link"
    }
  },
  mounted() {
    const userId = this.$route.params.id
    const hash = this.$route.params.hash

    this.verifyEmail({ userId: userId, hash: hash })
  },
  computed: {
    ...mapState("status", ["status_type", "message"]),
    alertType() {
      const color = this.status == "success" ? "alert-success" : "alert-danger"
      return color
    }
  },
  methods: {
    ...mapActions("verify", ["verifyEmail"]),
    goToHome() {
      this.$router.push("/")
    }
  },
  watch: {
    status_type(val) {
      if (val != null) {
        this.status = val
      }
    },
    message(val) {
      if (val != null) {
        this.content = val
      }
    }
  }
}
</script>
